import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes: [{
			path: '/',
			name: 'index',
			component: () => import('@/views/index.vue')
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/login.vue')
		},
		{
			path: '/preInfo',
			name: 'preInfo',
			component: () => import('@/views/exam/preInfo.vue')
		},
		{
			path: '/exam',
			name: 'exam',
			component: () => import('@/views/exam/web.vue')
		},
		{
			path: '/examApp',
			name: 'examApp',
			component: () => import('@/views/exam/app.vue')
		},
		{
			path: '/success',
			name: 'success',
			component: () => import('@/views/exam/success.vue')
		},
		{
			path: '*',
			name: '404',
			component: () => import('@/views/404/index.vue')
		}
	]
})

export default router